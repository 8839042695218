<template>
  <div>
    <!-- <page-description :path="[
            {label: $t('data.gestion_menu_mes_annonces'), route: 'annonces'},
            {label: 'discussions', route: 'discussions'}, 
        ]" /> -->
    <page-description
      :path="[
        { label: $t('data.gestion_menu_mes_annonces'), route: 'annonces' },
        { label: 'Mes reservations', route: 'reservations' },
      ]"
    />
    <annonces v-if="path == ''" />
        <!-- { label: $t('data.sidebar_discussions'), route: 'discussions' },
        { label: 'Mes rendez-vous', route: 'discussions' }, -->
    <router-view v-else />
  </div>
</template>

<script>
import Annonces from "./annonces/Annonces.vue";
export default {
  components: {
    Annonces,
  },
  data: () => ({
    path: "",
  }),
  created() {
    this.getPath();
  },
  mounted() {
    this.getPath();
  },
  updated() {
    this.getPath();
  },
  methods: {
    getPath() {
      this.path = this.$route.path.replace("/promotion", "");
    },
  },
};
</script>

<style></style>
